import {
    Scene,
    Vector3,
    WebGLRenderer,
    BoxGeometry,
    MeshBasicMaterial,
    Mesh,
    FontLoader,
    TextGeometry,
    PerspectiveCamera,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import '../css/sidebar.css';

let camera,
    controls,
    scene,
    renderer,
    cube,
    targetPositionY,
    targetPositionZ,
    targetRotationX,
    text,
    zoomed = true,
    mouseX = 0,
    mouseY = 0,
    target = new Vector3();

const arrow = document.getElementById('arrow');
const sidebar = document.getElementById('sidebar');
const description = document.getElementById('description');
const zoomout = document.getElementById('zoomout');

const windowHalfX = window.innerWidth / 2;
const windowHalfY = window.innerHeight / 2;

if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    init();
    animate();
}

function init() {
    sidebar.classList.add('notransition');
    sidebar.classList.remove('sidebar-show');
    description.classList.add('notransition');
    description.classList.remove('description-show');
    scene = new Scene();

    renderer = new WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);

    document.getElementById('animation').appendChild(renderer.domElement);

    camera = new PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    );
    camera.position.set(0, 2, 3);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.listenToKeyEvents(window);

    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    controls.screenSpacePanning = false;
    controls.enablePan = false;
    controls.enableRotate = false;

    controls.minDistance = 3;
    controls.maxDistance = 5;
    controls.zoomSpeed = 4;

    controls.maxPolarAngle = Math.PI / 2;

    // world

    const boxGeometry = new BoxGeometry(1, 1, 1);
    const material = new MeshBasicMaterial({
        color: 0xb6b42d,
    });

    cube = new Mesh(boxGeometry, material);
    scene.add(cube);

    const loader = new FontLoader();
    loader.load(
        'https://unpkg.com/three@0.77.0/examples/fonts/helvetiker_regular.typeface.json',
        function (font) {
            const textGeometry = new TextGeometry('Cheng-Zhen Yang', {
                font: font,
                size: 0.4,
                height: 0.01,
                curveSegments: 2,
                bevelEnabled: true,
                bevelThickness: 2,
                bevelSize: 0,
                bevelOffset: 0,
                bevelSegments: 0,
            });
            text = new Mesh(textGeometry, material);
            text.position.x = -2.3;
            text.position.y = 0.5;
            targetPositionY = 0.5;
            text.position.z = -2;
            targetPositionZ = -2;

            targetRotationX = 0;
            scene.add(text);
        }
    );

    window.addEventListener('resize', onWindowResize, { passive: true });
    document.addEventListener('mousemove', onDocumentMouseMove, false);
}

function onDocumentMouseMove(event) {
    mouseX = event.clientX - windowHalfX;
    mouseY = event.clientY - windowHalfY;
}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
}

function animate() {
    requestAnimationFrame(animate);

    target.x += (mouseX / 200 - target.x) * 0.01;
    target.y += (-mouseY / 300 - target.y) * 0.01;
    target.z = camera.position.z; // assuming the camera is located at ( 0, 0, z );

    cube.rotation.x += 0.005;
    cube.rotation.y += 0.005;
    controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true

    let zoom = controls.target.distanceTo(controls.object.position);

    if (zoom > 4 && zoomed) {
        arrow.style.setProperty('animation-iteration-count', '1');
        sidebar.classList.add('sidebar-show');
        description.classList.add('description-show');
        zoomout.classList.remove('zoomout-show');
        sidebar.classList.remove('notransition');
        description.classList.remove('notransition');
        targetPositionY = -1.5;
        targetPositionZ = 1;
        targetRotationX = -7;
        zoomed = false;
        //window.removeEventListener('resize', onWindowResize, { passive: true });
    } else if (zoom <= 4 && !zoomed) {
        arrow.style.removeProperty('animation-iteration-count');
        sidebar.classList.remove('sidebar-show');
        description.classList.remove('description-show');
        zoomout.classList.add('zoomout-show');
        targetPositionY = 0.5;
        targetPositionZ = -2;
        targetRotationX = 0;
        zoomed = true;
        //window.addEventListener('resize', onWindowResize, { passive: true });
    }

    if (text != null) {
        text.position.y += (targetPositionY - text.position.y) * 0.01;
        text.position.z += (targetPositionZ - text.position.z) * 0.01;
        text.rotation.x += (targetRotationX - text.rotation.x) * 0.01;
    }

    scene.lookAt(target);

    render();
}

function render() {
    renderer.render(scene, camera);
}
